import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Page404 } from "./pages/404/404.component";

import { AdminDashboardLayout } from "./layout/AdminDashboardLayout.component";

import { FrontOfficeLayout } from "./layout/FrontOfficeLayout.component";
import { generateRoute } from "./routes";

import { adminSidebarRoutes, userSidebarRoutes } from "./routes/sidebarRoutes";

import { rootRoutes } from "./routes/rootRoutes";
import ScrollToTop from "./utils/FixScrollToTop";
import { PageTemplateComponent } from "./pages/PageTemplate/PageTemplate.component";

import { useEffect } from "react";
import { loadSimplePages } from "./store/simplePages/simplePages-thunk";
import { useAppDispatch, useAppSelector } from "./utils/hooks";

import { Unsubscribe } from "./pages/Unsubscribe/Unsubscribe.component";

import { DashboardLayout } from "./layout/DashboardLayout.component";
import ProtectedRoleRoute from "./pages/Protect/ProtectRole.component";
import { Page } from 'store/simplePages/simplePages-inderfaces';
import { MarketingLayout } from 'layout/MarketingLayout.component';
import { Contact } from 'pages/Contact/Contact.component';
import { ThankYComponent } from 'pages/PageTemplate/templates/ThankYouTemplate.component';

export const Root = (): JSX.Element => {
  const simplePages = useAppSelector((state) => state.pages.pages);

  const user = useAppSelector((state) => state.userState.user);
  const token = useAppSelector((state) => state.userState.token);

  const spaces = useAppSelector((state) => {
    return state.allSpaces.spaces;
  });

 const getCustomPageRoute = (page: Page) => {
   switch (page.type) {
     case "marketing":
       return (
         <Route
           path={page.slug}
           key={`/${page.title}`}
           element={
             <MarketingLayout>
               <PageTemplateComponent id={page.id} />
             </MarketingLayout>
           }
         />
       );
     case "default":
       return (
         <Route
           path={page.slug}
           key={`/${page.title}`}
           element={
             <FrontOfficeLayout
               headerData={{
                 title: "",
                 text: page.title,

                 variant: "middleOffice",
               }}
             >
               <PageTemplateComponent id={page.id} />
             </FrontOfficeLayout>
           }
         />
       );
   }
 };


  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadSimplePages());
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {rootRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <FrontOfficeLayout headerData={route.headerData}>
                  {route.element}
                </FrontOfficeLayout>
              }
            />
          ))}
          {simplePages.map((page: Page) => getCustomPageRoute(page))}

          <Route
            element={
              <ProtectedRoleRoute redirectPath="/" isAllowed={!!token} />
            }
          >
            <Route path="/" element={<DashboardLayout />}>
              {generateRoute(userSidebarRoutes, spaces)}
            </Route>
          </Route>

          <Route
            element={
              <ProtectedRoleRoute
                redirectPath="/"
                isAllowed={!!token && user?.role?.includes("admin")}
              />
            }
          >
            <Route path="/" element={<AdminDashboardLayout />}>
              {generateRoute(adminSidebarRoutes, spaces)}
            </Route>
          </Route>

          <Route path="/unsubscribe/sh/:sh" element={<Unsubscribe />} />
          <Route path={`/:sh/thankyou`} element={<ThankYComponent />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
