import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Button, ErrorField, Htag } from "components";
import { useFormik } from "formik";
import * as yup from "yup";
import cn from "classnames";
import s from "./marketing_newsletter.module.css";
import axios from "axios";
import { RSS_SEND } from "store/config";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),

  consent: yup.bool().oneOf([true], "Please provide your consent to proceed"),
});

export const MarketingNewsletter = () => {
  const [serverErr, setServerErr] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      consent: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(RSS_SEND(), values, {
          headers: {
            Accept: "application/json",
          },
        })
        .then(() => {


          window.datahappy
            .trackLeadCreation({
              eventProperties: {
                value: 10, // common to track a value for lead events
                currency: "GBP",
                lead_source: "magnet",
              },
              userTraits: values,
            })
            .then(function () {
              navigate(`${location.pathname}/thankyou`, { replace: true });
              setLoading(false);
            })
            .catch((err: Error) => {
              console.log(err);
            });

        })
        .catch((err) => {
          console.log(err);
          if (Array.isArray(err)) {
            setServerErr(err[0].email);
          } else {
            setServerErr("Server error, try later");
          }
          //
          setLoading(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={s.form}>
        <Htag tag="h3">Email address</Htag>
        <FormControl fullWidth>
          <input
            id="email"
            name="email"
            type="text"
            className={cn(s.inputLogin, {
              [s.error_field]: formik.errors.email && formik.touched.email,
            })}
            placeholder="email"
            value={formik.values.email || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <ErrorField error={formik.errors.email} />
          ) : null}
        </FormControl>

        <p>
          <FormControlLabel
            value={formik.values.consent}
            className=""
            control={
              <Checkbox
                color="default"
                onBlur={formik.handleBlur}
                name="consent"
                onChange={formik.handleChange}
              />
            }
            label=""
          />
          <label>
            Consent to receive information from SeparateSpace. You can
            unsubscribe anytime. View our{" "}
            <NavLink
              className={cn(s.checkbox_link, "hover-underline-animation_back")}
              to="/privacy-policy"
            >
              privacy policy
            </NavLink>{" "}
            here
          </label>
        </p>

        {formik.touched.consent && formik.errors.consent && (
          <ErrorField error={formik.errors.consent} />
        )}

        {serverErr && <ErrorField error={serverErr} />}
        <Button
          apearance="secondary"
          type="submit"
          disabled={loading || !formik.isValid || !formik.dirty}
        >
          Get the checklist
        </Button>
      </form>
    </>
  );
};
