import { useState, useCallback, ChangeEvent } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Htag, Accordion, Button, ErrorField } from "components";
import { FullModalStyled } from "components/FullWidthModal/FullModal.component";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";

import s from "./GeneralOnboarding.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";

const desc = `<p>Scotland and Ireland have a different legal system to England and Wales. If you have a significant connection to Scotland or Ireland, you should tick 'yes' above.</p>`;

const validationSignupSchema = yup.object({
  not_foreigner: yup.string().required("Please select an option."),
});

export const FormGB2 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const [remind, setRemind] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name === "not_foreigner") {
        const isForeigner = value === "yes";

        setRemind(isForeigner);
        dispatch(isForeigner ? addId("D2") : removeId("D2"));
      }

      formik.handleChange(event);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const handleClose = () => {
    setRemind(false);
  };

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-2" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>
      <Htag tag="h3">
        Do you or your ex have significant connections to a country other than
        England or Wales? For example, a passport, nationality or citizenship
        for another country, a property abroad or do you spend time each year
        living in another country.
      </Htag>
      <div className={s.form_oneline}>
        <div className={s.formField}>
          <RadioGroup row name="not_foreigner" className={s.radioboxes}>
            <FormControlLabel
              value="no"
              checked={formik.values.not_foreigner == "no"}
              control={<Radio color="default" onChange={handleInputChange} />}
              label="No"
            />
            <FormControlLabel
              value="yes"
              checked={formik.values.not_foreigner == "yes"}
              control={<Radio color="default" onChange={handleInputChange} />}
              label="Yes"
            />
          </RadioGroup>
          {formik.touched.not_foreigner && formik.errors.not_foreigner && (
            <ErrorField error={formik.errors.not_foreigner} />
          )}
        </div>
      </div>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `What if I have a connection to Scotland or Ireland? I have a UK passport.`,
            content: desc,
          },
        ]}
      />
      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
      <FullModalStyled open={remind} close={handleClose} color="dark">
        <div className={s.firstSlide}>
          <p>
            * As you or your ex have a connection to another country, you should
            consult a legal expert in that country to find out about any rights
            you may have there. You can still use SeparateSpace but keep in mind
            it is designed to support people through divorce in England and
            Wales only.
          </p>
          <div className={s.groupButton}>
            <Button
              onClick={handleClose}
              className={s.dark}
              apearance="secondary"
            >
              Ok
            </Button>
          </div>
        </div>
      </FullModalStyled>
    </form>
  );
};
