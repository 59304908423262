import cn from "classnames";
import styles from "../OnBoarding.module.css";
import { Button, ErrorField, Htag, StepCircle } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  addServerError,
  setBoardingStep,
} from "store/onBoarding/onboarding-reducer";
import * as yup from "yup";
import { useRef, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { RESEND_VERIFY_EMAIL } from "store/config";
import {
  CustomSnackbar,
  CustomSnackbarRef,
} from "components/CustomSnackbar/CustomSnackbar.component";
export const validationSignupSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});
export const ConfirmEmailStep = (): JSX.Element => {
  const { activeStep, server_error } = useAppSelector(
    (state) => state.onRegistration
  );
  const { email } = useAppSelector((state) => state.userState.user);
  const token = useAppSelector((state) => state.userState.token);
  const ref = useRef<CustomSnackbarRef>(null);
  const [showResendForm, setShowResendForm] = useState(false);
  const [server_answer, setServer_answer] = useState("");

  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSignupSchema,
    onSubmit: (values) => {
      axios
        .post(RESEND_VERIFY_EMAIL(), JSON.stringify(values), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          responseType: "json",
        })
        .then((res) => {
          ref.current?.refresh();
          setServer_answer(res.data.success);
          dispatch(addServerError(""));
        })
        .catch((error) => {
          dispatch(addServerError(error.response.data.error));
        });
    },
  });


  if (token) {
    return (
      <div className={styles.form_group}>
        <header className={styles.onboarding_header}>
          <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
            <span className={styles.step_color}>{activeStep + 1}</span>
          </StepCircle>
          <Htag tag="h4">Your email is now confirmed</Htag>
        </header>
        <p>The next step is to choose your plan.</p>
        <div className={styles.buttonGroup}>
          <Button
            apearance="secondary"
            type="button"
            onClick={() => dispatch(setBoardingStep(activeStep))}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  } else if (!showResendForm) {
    return (
      <div className={styles.form_group}>
        <header className={styles.onboarding_header}>
          <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
            <span className={styles.step_color}>{activeStep + 1}</span>
          </StepCircle>
          <Htag tag="h4">Now confirm your email address</Htag>
        </header>
        <p>
          We’ve sent an email to {email}. Click the button inside the email to
          confirm your account. You may need to check your spam folder for the
          email.{" "}
        </p>
        <p>
          Didn’t receive the email?{" "}
          <span
            className={cn(styles.resendLink, "hover-underline-animation_back")}
            onClick={() => {
              setShowResendForm(true);
            }}
          >
            Resend it.
          </span>
        </p>
      </div>
    );
  } else {
    return (
      <>
        <form
          id="form-1"
          onSubmit={formik.handleSubmit}
          className={cn(styles.form_group, styles.interview_form)}
        >
          <header className={styles.onboarding_header}>
            <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
              <span className={styles.step_color}>{activeStep + 1}</span>
            </StepCircle>
            <Htag tag="h4">Re-enter your email address</Htag>
          </header>
          <p>We will resend the confirmation link.</p>
          <div className={styles.formField}>
            <label className={styles.label}>Email address</label>

            <input
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
            />
            {formik.touched.email && formik.errors.email && (
              <ErrorField error={formik.errors.email} />
            )}
            <ErrorField error={server_error?.toString()} />
          </div>
          <div className={styles.buttonGroup}>
            <Button apearance="secondary" type="submit">
              Resend
            </Button>
          </div>
        </form>

        <CustomSnackbar message={server_answer} ref={ref} />
      </>
    );
  }
};
