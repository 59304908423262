import s from "./GeneralOnboarding.module.css";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";
import { Accordion, Button, ErrorField, Htag } from "components";
import * as yup from "yup";
import { useFormik } from "formik";
const validationForm11 = yup.object({
  trust_beneficiary: yup
    .string()
    .nullable()
    .required("Please select an option."),
});

const answers = [
  {
    title: "What does this question mean?",
    content:
      "A trust is a legal structure established to hold assets, such as investment funds or property, for the benefit of certain named individuals; the beneficiaries and is often set up for tax or inheritance purposes.  Beneficiaries do not hold the legal title to the assets directly but may have a beneficial interest.  Trustees are usually appointed to manage the trust in accordance with the rules set out in the underlying legal trust document. ",
  },
  {
    title: "Why are we asking this?",
    content:
      "As you know, the first stage of the financial separation process requires each person to provide full and complete information about all their assets and liabilities and this includes any entitlement under a trust.  Information about any interest you may have in a trust needs to be provided, even if you have never received any capital or income from this source and have no expectation of receiving any in the future.  It is common for trust assets to be considered in a separate category to other assets and if this is relevant to you, we’ll give you more information about it.",
  },
];
export const FormGB11 = ({
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );
  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      if (value == "yes" || value == "maybe") {
        dispatch(addResourceId(["FR20"]));
      } else if (value == "no") {
        dispatch(removeResourceId(["FR20"]));
      }
      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm11,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-11" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3">Are you or your ex a beneficiary of a trust? </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="trust_beneficiary" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.trust_beneficiary == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.trust_beneficiary == "no"}
              />
              <FormControlLabel
                value="maybe"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Don’t know but maybe"
                checked={formik.values.trust_beneficiary == "maybe"}
              />
            </RadioGroup>
            {formik.touched.trust_beneficiary &&
              formik.errors.trust_beneficiary && (
                <ErrorField error={formik.errors.trust_beneficiary} />
              )}
          </div>
        </div>
      </>

      <Accordion className={s.desc} accordionData={answers} />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
