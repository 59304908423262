import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ACTIVITIES_DATA, GET_ANALITIC_DATA, GET_USERS_DATA_ALL_STATISTIC, GET_USERS_DATA_STATISTIC } from "../config";
import { IInitState } from "../root-interface";
import { setToken } from "../userInfo/userInfo-reducer";
import { IChart, UserAnaliticData, UsersActivitiesData } from "./analytics.interface";

export const getChartsData = createAsyncThunk<
  { chartData: IChart[]; userData: UserAnaliticData },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getChartsData",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(GET_ANALITIC_DATA(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        chartData: IChart[];
        userData: UserAnaliticData;
      };
    }
  }
);
export const getUsersDataStatistic = createAsyncThunk<
  { userData: UserAnaliticData },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getUsersDataStatistic",
  async function (time, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(GET_USERS_DATA_STATISTIC(time), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        userData: UserAnaliticData;
      };
    }
  }
);


export const getUsersDataAllDaysStatistic = createAsyncThunk<
  { userData: UserAnaliticData },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getUsersDataAllDaysStatistic",
  async function (time, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(GET_USERS_DATA_ALL_STATISTIC(time), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        userData: UserAnaliticData;
      };
    }
  }
);


export const getLastActivitiesData = createAsyncThunk<
  UsersActivitiesData,
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getActivitiesData",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(GET_ACTIVITIES_DATA(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as UsersActivitiesData;
    }
  }
);


