import DashboardPageLayout from "../layout/DashboardPageLayout.component";
import { AddPage } from "../pages/AdminDashboard/AddPage/AddPage.component";
import { AdminDashboard } from "../pages/AdminDashboard/AdminDashboard.component";
import { AllPages } from "../pages/AdminDashboard/ContentTemplates/ContentTemplates.component";
import { ListSystemResources } from "../pages/AdminDashboard/SpecialResources/ListSR.component";
import { ListSystemTickets } from "../pages/AdminDashboard/SpecialTickets/ListST.component";
import { AddSources } from "../pages/AdminDashboard/WellbeingAdmin/AddSources/AddSources.component";
import { ListSourceWelbeing } from "../pages/AdminDashboard/WellbeingAdmin/ListSourceWelbeing/ListSourceWelbeing.component";
import { WellbeingAdmin } from "../pages/AdminDashboard/WellbeingAdmin/WellbeingAdmin.component";
import { WellbeingAdminList } from "../pages/AdminDashboard/WellbeingAdmin/WellbeingAdmin.list.component";
import { Dashboard } from "../pages/Dashboard/Dashboard.component";
import { SavedArticles } from "../pages/Dashboard/SavedArticles/SavedArticles.component";
import { SavedSingleArticle } from "../pages/Dashboard/SavedArticles/SavedSingleArticle.component";
import { WellBeingIndex } from "../pages/Dashboard/Wellbeing/Welbeing.index.component";
import { WelbeingChallengeItem } from "../pages/Dashboard/Wellbeing/WelbeingChallengeItem.component";
import { WelbeingChallenges } from "../pages/Dashboard/Wellbeing/WelbeingChallenges.component";
import { WellbeingPage } from "../pages/Dashboard/Wellbeing/Wellbeing.component";
import { WellBeingFeelling } from "../pages/Dashboard/Wellbeing/WellBeingFeelling.component";

import { RouteType } from "./config";
import { WellbeingArticleComponent } from "../pages/Dashboard/Wellbeing/WellBeingArticle.component";
import { User } from "../pages/Dashboard/User/User.component";
import { EditPage } from "../pages/AdminDashboard/AddPage/EditPage.component";
import { EditFeeling } from "../pages/AdminDashboard/WellbeingAdmin/EditFeeling/EditFeeling.component";
import { EditChallenge } from "../pages/AdminDashboard/WellbeingAdmin/EditFeeling/EditChallenge.component";
import { EditSourcePage } from "../pages/AdminDashboard/WellbeingAdmin/AddSources/EditSources.component";
import { EditWSSourcePage } from "../pages/AdminDashboard/WellbeingAdmin/AddSources/EditWSystemSources.component";
import { EditSTPage } from "../pages/AdminDashboard/SpecialTickets/EditST.component";
import { EditSRPage } from "../pages/AdminDashboard/SpecialResources/EditSR.component";
import { PreviewWSource } from "../pages/AdminDashboard/WellbeingAdmin/AddSources/PreviewSource.component";
import { ResourceArticleComponent } from "../pages/Dashboard/Spaces/SResources/ResourceArticle.component";
import { PreviewTicket } from "../pages/AdminDashboard/SpecialTickets/PreviewTicket";
import { ListExtraResources } from "../pages/AdminDashboard/ExtraResources/ListER.component";
import { AddExtraResource } from "../pages/AdminDashboard/ExtraResources/AddExtraSources.component";
import { EditERPage } from "../pages/AdminDashboard/ExtraResources/EditER.component";
import { EResourceArticleComponent } from "../pages/Dashboard/Spaces/ExtraArticles/ResourceArticle.component";
import { WaitingList } from "../pages/AdminDashboard/WaitingList/ListWaiting.component";
import { SearchResults } from "../pages/Dashboard/SearchResults/SearchResults.component";
import { SubscriptionList } from "../pages/AdminDashboard/WaitingList/ListSubscribers.component";
import { BlogList } from "../pages/AdminDashboard/Blog/BlogList.component";
import { AddBlogPost } from "../pages/AdminDashboard/Blog/AddBlogPage.component";
import { EditPost } from "../pages/AdminDashboard/Blog/EditBlogPage.component";
import { AddCaseStudies } from "../pages/AdminDashboard/CaseStudies/AddCaseStudies.component";
import { CaseStudyUser } from "../pages/Dashboard/CaseStudies/CaseStudyUser.component";
import { ListCaseStudies } from "../pages/AdminDashboard/CaseStudies/CaseStudiesList.component";
import { EditCSPage } from "../pages/AdminDashboard/CaseStudies/CSEdit.component";

import { SpacePage } from "../pages/Dashboard/Spaces/Space.component";
import { SingleTicket } from "../pages/Dashboard/Spaces/SingleTicket/SingleTicket.component";
import { MilestoneLayout } from "../layout/MilestoneLayout.component";
import PathWayComponent from "../pages/Dashboard/PathWay/PathWay.component";
import { ListSpecialCaseStudies } from "../pages/AdminDashboard/CaseStudies/SpecialCaseStudiesList.component";
import { IconsPage } from "pages/Icons-page.component";
import { TotalWidgetsView } from "pages/AdminDashboard/Widgets/TotalWidgetsView.component";
import { AdminAnalytics } from "pages/AdminDashboard/AdminAnalytics/AdminAnalytics.components";
import { QuestionnaireAnalytics } from 'pages/AdminDashboard/AdminAnalytics/QuestionnairesAnalytics.component';
import Home from 'pages/Home/Home.components';

export const adminSidebarRoutes: RouteType[] = [
  {
    element: <SubscriptionList />,
    state: "adminDashboard.index",
    path: "/admin-dashboard",
  },
  {
    path: "/icons",
    element: <IconsPage />,
    state: "adminIcons",
  },
  {
    element: <DashboardPageLayout />,
    state: "analytics",

    menuProps: {
      displayText: "Analytics",
    },
    child: [
      {
        element: <SubscriptionList />,
        path: "/admin-dashboard/users",
        state: "analytics.users",
        menuProps: {
          displayText: "All members",
        },
      },
      {
        element: <AdminAnalytics />,
        path: "/admin-dashboard/analytics",
        state: "analytics.index",
        menuProps: {
          displayText: "Analytics",
        },
      },
      {
        element: <QuestionnaireAnalytics />,
        path: "/admin-dashboard/analyticsQuestionnaires",
        state: "analytics",
        menuProps: {
          displayText: "Questionnares Data",
        },
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "adminSystemTickets",

    menuProps: {
      displayText: "System tickets",
    },
    child: [
      {
        element: <ListSystemTickets />,
        path: "/admin-dashboard/system-tickets",
        state: "adminSystemTickets.tickets",
        menuProps: {
          displayText: "System tickets",
        },
      },
      {
        element: <EditSTPage />,
        path: "admin-dashboard/system-tickets/edit-ticket/:id",
        state: "adminSystemTickets.tickets",
      },
      {
        element: <PreviewTicket />,
        path: "/admin-dashboard/system-tickets/edit-ticket/:ticketId/preview",
        state: "adminSystemTickets.tickets",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "adminSystemSources",
    menuProps: {
      displayText: "Wellbeing sources",
    },
    child: [
      {
        element: <WellbeingAdminList />,
        path: "/admin-dashboard/wellbeing",
        state: "adminSystemSources.wellbeing",
        menuProps: {
          displayText: "Wellbeing emotions and challenges",
        },
      },

      {
        element: <WellbeingAdmin />,
        path: "/admin-dashboard/add-new-challenge/",
        state: "adminSystemSources.wellbeing",
        menuProps: {
          displayText: "Add new wellbeing challenge",
        },
      },

      {
        element: <ListSourceWelbeing />,
        path: "/admin-dashboard/wellbeingSources",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
        menuProps: {
          displayText: "Tools and resources lists",
        },
      },
      {
        element: <EditWSSourcePage />,
        path: "/admin-dashboard/wellbeingSources/sresource/edit-page/:id",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <EditSourcePage />,
        path: "/admin-dashboard/wellbeingSources/resource/edit-page/:id",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <EditFeeling />,
        path: "/admin-dashboard/wellbeing/feeling/edit-page/:id",
        state: "adminSystemSources.wellbeing",
      },
      {
        element: <EditChallenge />,
        path: "/admin-dashboard/wellbeing/challenge/edit-page/:id",
        state: "adminSystemSources.wellbeing",
      },
      {
        element: <EditSourcePage />,
        path: "/admin-dashboard/wellbeingSources/tool/edit-page/:id",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeingSources/sresource/edit-page/:id/preview/",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeingSources/resource/edit-page/:id/preview/",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeingSources/tool/edit-page/:id/preview/",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <AddSources />,
        path: "/admin-dashboard/add-wellbeing-tools-resources",
        state: "adminSystemSources.wellbeing.addEdit",
        menuProps: {
          displayText: "Add wellbeing sources",
        },
      },
    ],
  },

  {
    element: <DashboardPageLayout />,
    state: "spaceArticles",
    menuProps: {
      displayText: "Space Resources",
    },
    child: [
      {
        element: <ListSystemResources />,
        path: "/admin-dashboard/system-resources",
        state: "spaceArticles.resources",
        menuProps: {
          displayText: "System  resources",
        },
      },

      {
        element: <EditSRPage />,
        path: "/admin-dashboard/system-resources/edit-resource/:id",
        state: "spaceArticles.resources",
      },
      {
        element: <ResourceArticleComponent />,
        path: "/admin-dashboard/system-resources/edit-resource/:resourceId/preview",
        state: "spaceArticles.resources",
      },
      {
        element: <ListExtraResources />,
        path: "/admin-dashboard/extra-resources",
        state: "spaceArticles.extra-resources",
        menuProps: {
          displayText: "Extra resources",
        },
      },
      {
        element: <EditERPage />,
        path: "/admin-dashboard/extra-resources/edit-page/:resourceId",
        state: "spaceArticles.extra-resources",
      },
      {
        element: <AddExtraResource />,
        path: "/admin-dashboard/add-resources",
        state: "spaceArticles.extra-resources",
        menuProps: {
          displayText: "Add extra resources",
        },
      },
      {
        element: <EResourceArticleComponent />,
        path: "/admin-dashboard/extra-resources/edit-page/:resourceId/preview",
        state: "spaceArticles.resources",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "blog",
    menuProps: {
      displayText: "Blog",
    },
    child: [
      {
        element: <BlogList />,
        path: "/admin-dashboard/blog",
        state: "blog",
        menuProps: {
          displayText: "All pages",
        },
      },
      {
        element: <EditPost />,
        path: "/admin-dashboard/blog/edit-page/:slug",
        state: "blog.edit-post",
      },
      {
        element: <AddBlogPost />,
        path: "/admin-dashboard/add-post",
        state: "blog.addNewPost",
        menuProps: {
          displayText: "Add new post",
        },
      },
    ],
  },

  {
    element: <DashboardPageLayout />,
    state: "caseStudies",
    menuProps: {
      displayText: "Case Studies",
    },
    child: [
      {
        element: <ListCaseStudies />,
        path: "/admin-dashboard/caseStudies/list",
        state: "caseStudies.list",
        menuProps: {
          displayText: "Case studies list",
        },
      },
      {
        element: <ListSpecialCaseStudies />,
        path: "/admin-dashboard/caseStudies/special_list",
        state: "caseStudies.special_list",
        menuProps: {
          displayText: "Special Case studies list",
        },
      },
      {
        element: <AddCaseStudies />,
        path: "/admin-dashboard/add-newCS",
        state: "caseStudies.new",
        menuProps: {
          displayText: "Add new case study",
        },
      },

      {
        element: <CaseStudyUser />,
        path: "/admin-dashboard/caseStudies/edit-page/:id/preview",
        state: "caseStudies.preview",
      },

      {
        element: <EditCSPage />,
        path: "/admin-dashboard/caseStudies/edit-page/:id",
        state: "caseStudies.edit-cs",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "subscribers",
    menuProps: {
      displayText: "Waiting list",
    },
    child: [
      {
        element: <WaitingList />,
        path: "/admin-dashboard/waiting-list",
        state: "subscribers.index",
        menuProps: {
          displayText: "Waiting List",
        },
      },
    //   {
    //     element: <SubscriptionList />,
    //     path: "/admin-dashboard/users-list",
    //     state: "subscribers",
    //     menuProps: {
    //       displayText: "Registered List",
    //     },
    //   },
      //   {
      //     element: <RefundRequestList />,
      //     path: "/admin-dashboard/refundrequest-list",
      //     state: "subscribers",
      //     menuProps: {
      //       displayText: "Refund Requests",
      //     },
      //   },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "contentPages",
    menuProps: {
      displayText: "Content pages",
    },
    child: [
      {
        index: true,
        element: <AdminDashboard />,
        path: "/admin-dashboard/content-pages",
        state: "contentPages.index",
      },
      {
        path: "/admin-dashboard/all-pages",
        element: <AllPages />,
        state: "contentPages.allSimplePage",
        menuProps: {
          displayText: "All simple pages",
        },
      },
      {
        path: "/admin-dashboard/all-pages/edit-page/:id",
        element: <EditPage />,
        state: "contentPages.allSimplePage",
      },
      {
        path: "/admin-dashboard/add-page",
        element: <AddPage />,
        state: "contentPages.addPage",
        menuProps: {
          displayText: "Add new page",
        },
      },
      {
        path: "/admin-dashboard/widgets",
        element: <TotalWidgetsView />,
        state: "contentPages.widgets",
        menuProps: {
          displayText: "Widgets",
        },
      },
    ],
  },
];

export const userSidebarRoutes: RouteType[] = [
  {
    path: "/",
    element: <Home />,
    state: "home",
    menuProps: {
      displayText: "Home",
    },
  },
  {
    path: "/profile",
    element: <User />,
    state: "profile",
    menuProps: {
      displayText: "Profile",
    },
  },

  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
    state: "dashboard",
    menuProps: {
      displayText: "Dashboard",
    },
    child: [
      {
        index: true,
        element: <Dashboard />,
        path: "/dashboard/",
        state: "dashboard.index",
      },

      {
        path: "/dashboard/milestone/:id",
        element: <MilestoneLayout />,
        state: `dashboard.milestone`,
      },
      {
        path: "/dashboard/:space/:id/:ticketId",
        element: <SingleTicket />,
        state: `dashboard.ticket`,
      },
      {
        element: <ResourceArticleComponent />,
        path: "/dashboard/resources/:resourceId",
        state: `dashboard.resources`,
      },
      {
        menuProps: {
          displayText: "Children",
        },
        path: `/dashboard/space/:id`,
        url: `/dashboard/space/2`,
        element: <SpacePage />,
        state: `dashboard.children`,

        child: [
          {
            index: true,
            path: "/dashboard/space/:id",
            element: <SpacePage />,
            state: `dashboard.children.index`,
          },
        ],
      },
      {
        path: "/dashboard/my-wellbeing",
        element: <WellbeingPage />,
        state: "dashboard.myWellbeing",
        menuProps: {
          displayText: "Wellbeing",
        },
        child: [
          {
            index: true,
            path: "/dashboard/my-wellbeing",
            element: <WellBeingIndex />,
            state: "dashboard.myWellbeing.index",
          },
          {
            path: "/dashboard/my-wellbeing/feelling",
            element: <WellBeingFeelling />,
            state: "dashboard.myWellbeing",
          },
          {
            path: "/dashboard/my-wellbeing/challenges",
            element: <WelbeingChallenges />,
            state: "dashboard.myWellbeing",
          },
          {
            path: "/dashboard/my-wellbeing/challenges/:id",
            element: <WelbeingChallengeItem />,
            state: "dashboard.myWellbeing",
          },
          {
            path: "/dashboard/my-wellbeing/:type/:typeId/:id",
            element: <WellbeingArticleComponent />,
            state: "dashboard.myWellbeing.resource",
          },
          {
            path: "/dashboard/my-wellbeing/resource/:id",
            element: <WellbeingArticleComponent />,
            state: "dashboard.myWellbeing.resource",
          },
          {
            path: "/dashboard/my-wellbeing/tool/:id",
            element: <WellbeingArticleComponent />,
            state: "dashboard.myWellbeing.resource",
          },
          {
            path: "/dashboard/my-wellbeing/feelling/:id",
            element: <WelbeingChallengeItem />,
            state: "dashboard.myWellbeing",
          },
        ],
      },
      {
        path: "/dashboard/bookmarks",
        element: <SavedArticles />,
        state: "dashboard.savedArticles",

        menuProps: {
          displayText: "Saved resources",
        },
      },
      {
        path: "/dashboard/pathway",
        element: <PathWayComponent />,
        state: "dashboard.pathway",
      },
      {
        path: "/dashboard/articles/:resourceId",
        element: <EResourceArticleComponent />,
        state: "dashboard",
      },
      {
        path: "/dashboard/bookmarks/:source/:id",
        element: <SavedSingleArticle />,
        state: "dashboard.savedArticles.singleArticle",
      },
      {
        element: <SearchResults />,
        path: "/dashboard/search-results/",
        state: "searchResults",
      },
      {
        element: <CaseStudyUser />,
        path: "/dashboard/case_studies/:id/",
        state: "dashboard.finance",
      },
    ],
  },
];
