import { Article } from "./Article.model";

export class Resource extends Article {
  private _tags: string[];
  image: Blob | null | string;
  unique_number: string;
  milestone_id?: number | null;
  show_in_dashboard: boolean;
  attachments: any[];

  constructor(
    title = "",
    content = "",
    reading_time = "",
    tags = [],
    image = "",
    unique_number = "",
    milestone_id = 1,
    show_in_dashboard = false,
    attachments = []
  ) {
    super(title, content, reading_time);
    this.image = image;
    this._tags = tags;
    this.unique_number = unique_number;
    this.milestone_id = milestone_id;
    this.show_in_dashboard = show_in_dashboard;
    this.attachments = attachments;
  }

  get tags() {
    return this._tags;
  }

  set tags(tags) {
    this._tags = tags;
  }

  formData(values: {
    title: string;
    content: string;
    reading_time: string;
    attachments: any[];
    milestone_id?: number | null;
    show_in_dashboard: boolean;
    image: Blob | null | string;
    tags: string[];
    unique_number: string;
  }) {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append("reading_time", values.reading_time);
    formData.append("unique_number", values.unique_number);
    formData.append(
      "show_in_dashboard",
      JSON.stringify(values.show_in_dashboard)
    );
    formData.append("type", "resource");
    if (values.image) {
      formData.append("image", values.image);
    } else {
      formData.append("image", "");
    }

    console.log("here");
    if (!values.tags.length) formData.append("tags", "");
    values.tags.map((tag: string) => {
      formData.append("tags[]", tag);
    });

    if (values.milestone_id)
      formData.append("milestone_id", values.milestone_id.toString());
    values.attachments.map((file) => {
      formData.append("attachments[]", file);
    });
    return formData;
  }
}
