import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { stringToDate } from 'utils/utils';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./dateRange.theme.css";
import { GridFilterInputValueProps } from '@mui/x-data-grid';

export function DateFilterComponent(props: GridFilterInputValueProps) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { item, applyValue, focusElementRef } = props;


  useEffect(() => {
    if (!item.value) return;
    const firsrDate = item.value.split("/")[0];
    const lastDate = item.value.split("/")[1];
    console.log(stringToDate(firsrDate));
    setState([
      {
        startDate: stringToDate(firsrDate),
        endDate: stringToDate(lastDate),
        key: "selection",
      },
    ]);
  }, []);

  const dateRangeRef: React.Ref<any> = useRef(null);
  //   useImperativeHandle(focusElementRef, () => ({
  //     focus: () => {
  //       if (!dateRangeRef.current) return;
  //       dateRangeRef.current
  //         .querySelector(`input[value="${Number(item.value) || ""}"]`)
  //         .focus();
  //     },
  //   }));

  const handleFilterChange: any = (value: any) => {
    applyValue({
      ...item,
      value: `${dayjs(value.selection.startDate).format("DD-MM-YYYY")}/${dayjs(
        value.selection.endDate
      ).format("DD-MM-YYYY")}`,
    });
    setState([value.selection]);
  };
  return (
    <DateRange
      editableDateInputs={true}
      onChange={handleFilterChange}
      moveRangeOnFirstSelection={false}
      ref={dateRangeRef}
      ranges={state}
    />
  );
}
