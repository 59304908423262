import styles from "../OnBoarding.module.css";
import s from "../../../../pages/Pricing/Pricing.module.css";
import { Button, Htag, StepCircle } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import { PaymentComponent } from "components/PaymentComponent/Payment.component";
import { setBoardingStep } from "store/onBoarding/onboarding-reducer";
import { useEffect } from "react";

export const ChoosePlanComponent = () => {
  const activeStep = useAppSelector((state) => state.onRegistration.activeStep);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBoardingStep(activeStep - 1));
  }, []);
  return (
    <>
      <div className={styles.form_group}>
        <header className={styles.onboarding_header}>
          <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
            <span className={styles.step_color}>{activeStep + 1}</span>
          </StepCircle>
          <Htag tag="h4">Choose your plan</Htag>
        </header>

        <PaymentComponent />

        <div>
          <Htag tag="h4">7 DAY MONEY BACK GUARANTEE</Htag>
          <p>Not for you? Tell us within 7 days and it’s on us.</p>
        </div>
        {/* <div className={styles.buttonGroup}>
          <Button apearance="secondary" type="submit">
            Continue to payment
          </Button>
        </div> */}
      </div>
    </>
  );
};
