import s from "../Questionnaire.module.css";
import { Accordion, Button, Htag } from "components";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import { setCQuestionnaire } from "store/questionnaires/questionnaires.reducer";
import { useRef, useState } from "react";
import {
  addId,
  removeId,
  resetTicketsIds,
} from "store/system_tickets/system_ticket-reducer";
import { QuestionnaireProps } from "../Questionnaire.props";
import { saveCOnboarding } from "store/questionnaires/questionnaires.thunk";
import { add_system_tickets } from "store/system_tickets/system-ticket-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { loadTickets } from "store/tickets/tickets-thunk";
import {
  addResourceId,
  removeResourceId,
  resetResourcesIds,
} from "store/system_resources/system_resource-reducer";
import { add_system_resources } from "store/system_resources/system_resource-thunk";
import { sortArray } from "utils/utils";

export const FormCB2 = ({
  close,
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const childrenOnboarding = useAppSelector(
    (state) => state.questionnairesState.cQuestionnaire
  );

  const system_tickets_id = useAppSelector(
    (state) => state.specialTicketsState.tickets
  );
  const [value, setValue] = useState<"yes" | "no" | null>(
    childrenOnboarding.children_know
  );
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const system_resources_id = useAppSelector(
    (state) => state.specialResourcesState.resources
  );

  const living_property = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.living_property
  );
  const firstGroup = useRef<HTMLInputElement>(null);
  const secondGroup = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const updateAdultGroup = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.name == "firstGroup" && ev.target.checked) {
      dispatch(addResourceId("CR3A"));
    } else if (ev.target.name == "firstGroup" && !ev.target.checked) {
      dispatch(removeResourceId("CR3A"));
    }
    dispatch(
      setCQuestionnaire({
        ...childrenOnboarding,
        adultGroup: {
          ...childrenOnboarding.adultGroup,
          [`${ev.target.name}`]: ev.target.checked,
        },
      })
    );
  };

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;

    setHelperText("");
    setError(false);

    if (value === "no") {
      setValue(value);
      if (childrenOnboarding.agesGroup.babies) {
        dispatch(addId("C8"));
      }
      if (childrenOnboarding.agesGroup.toddlers) {
        dispatch(addId("C9"));
      }
      if (childrenOnboarding.agesGroup.teenagers) {
        dispatch(addId("C10"));
      }
      if (onlyAdult()) {
        dispatch(addId(["C11"]));
      }
      dispatch(removeResourceId(["CR7"]));
    } else if (value === "yes") {
      setValue(value);
      dispatch(removeId(["C10", "C9", "C8", "C11"]));
      dispatch(addResourceId(["CR7"]));
    }
    setData?.(name, value);
  };

  const onlyAdult = (): boolean => {
    if (
      !childrenOnboarding.agesGroup.teenagers &&
      !childrenOnboarding.agesGroup.toddlers &&
      !childrenOnboarding.agesGroup.babies &&
      childrenOnboarding.agesGroup.adults
    ) {
      return true;
    } else return false;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (value === "yes" || value === "no") {
      setHelperText("");
      setError(false);
      if (onlyAdult()) {
        dispatch(saveCOnboarding(childrenOnboarding))
          .unwrap()
          .then(() => {
            dispatch(add_system_tickets(sortArray([...system_tickets_id])))
              .unwrap()
              .then(() => {
                dispatch(loadTickets(3));
                dispatch(resetTicketsIds());
              });
            dispatch(add_system_resources(sortArray([...system_resources_id])))
              .unwrap()
              .then(() => {
                dispatch(resetResourcesIds());
              });
            close?.();
          });
      } else {
        handleNextStep?.();
      }
    } else {
      setHelperText("Please select an option.");
      setError(true);
    }
  };

  return (
    <form id="form-2" onSubmit={handleSubmit} className={s.form}>
      <span className={s.stepMark}>2</span>

      <>
        <Htag tag="h3">
          In a moment you will see your Children Space and it will be populated
          with the resources relevant to the age of your children. First we have
          a few additional questions.
        </Htag>

        {childrenOnboarding.agesGroup.adults && (
          <>
            <p>
              We know that separation and divorce can be difficult for children
              even if they’re already adults. We’ll give you some hints and tips
              to help you support them and manage the response they may have to
              what’s going on. To make this as useful as possible, we’ve got
              another couple of questions for you.
            </p>
            <Htag tag="h3" style={{ marginTop: "30px" }}>
              Which age category do your adult children fall into? Please tick
              all that apply.
            </Htag>
            <div className={s.form_oneline}>
              <div className={s.formField}>
                <FormGroup>
                  <FormControlLabel
                    checked={childrenOnboarding.adultGroup.firstGroup}
                    name="firstGroup"
                    control={
                      <Checkbox
                        color="default"
                        onChange={(e) => updateAdultGroup(e)}
                        inputRef={firstGroup}
                      />
                    }
                    label="18-25"
                  />
                  <FormControlLabel
                    checked={childrenOnboarding.adultGroup.secondGroup}
                    name="secondGroup"
                    control={
                      <Checkbox
                        color="default"
                        onChange={updateAdultGroup}
                        inputRef={secondGroup}
                      />
                    }
                    label="26+"
                  />
                </FormGroup>
              </div>
            </div>
          </>
        )}

        {living_property == "different" && (
          <div className={s.box2}>
            <Htag tag="h3">
              Given you and your ex aren’t living together anymore do your
              children already know about your separation?
            </Htag>
            <div className={s.form_oneline}>
              <div className={s.formField}>
                <RadioGroup row name="children_know" className={s.radioboxes}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        checked={childrenOnboarding.children_know == "yes"}
                        onChange={updateInterview}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        color="default"
                        checked={childrenOnboarding.children_know == "no"}
                        onChange={updateInterview}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                {error && (
                  <FormHelperText className={s.error}>
                    {helperText}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        )}
        {living_property == "same" && (
          <>
            <Htag tag="h3">
              You’ve told us you and your ex are still living together. Do your
              children know about your separation?
       
            </Htag>
            <div className={s.form_oneline}>
              <div className={s.formField}>
                <RadioGroup row name="children_know" className={s.radioboxes}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        checked={childrenOnboarding.children_know == "yes"}
                        onChange={updateInterview}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        color="default"
                        checked={childrenOnboarding.children_know == "no"}
                        onChange={updateInterview}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                {error && (
                  <FormHelperText className={s.error}>
                    {helperText}
                  </FormHelperText>
                )}
              </div>
            </div>
          </>
        )}
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking this?`,
            content: `<p>If you need some support to plan how you are going to tell your children about what is happening in an age appropriate way, we can help.</p>`,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
