import {
  Accordion,
  Button,
  Card,
  CheckIcon,
  CustomerStories,
  ErrorField,
  Help,
  Htag,
  InstagramFeed,
  ModalStyled,
  Squar_round,
  StepCircle,
  SupportIcon,
  TeamIcon,
} from "components";
import "../../styles/global-style.css";
import s from "./Home.module.css";
import cn from "classnames";

import {
  breakthorugh,
  feature_2,
  pathWay,
  phone2,
  uKRI_IUK,
} from "./Home.images";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import testimonialData from "mock/testimonials.json";

import { useEffect, useState } from "react";

import {
  setBoardingStep,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Alert, Snackbar } from "@mui/material";

import { faq } from "./faq";

import { AB, ES, JH, VN } from "../Team/Team.images";
import { IsItRight } from "layout/IsItRight/IsItRight.component";
import { verifyEmailAction } from "store/onBoarding/onBoarding-thunk";
import { setToken, setUsedData } from "store/userInfo/userInfo-reducer";
import { GuideFormComponent } from "./guidForm/GuideForm.component";
import { ProffessionalFeedbackComponent } from "./ProffessionalFeedback.component";
import { GreatingSection } from "./greatingSection.component";
import { openLoginModal } from "store/login/login-reducer";
import { Helmet } from "react-helmet";

function Home() {
  const dispatch = useAppDispatch();
  const userToken = useAppSelector((state) => state.userState.token);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");

  const [showGuidMsg, setShowGuidMsg] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const handleResize = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener("resize", () => handleResize);
    return () => window.removeEventListener("resize", () => handleResize);
  }, []);


  useEffect(() => {
    const id = setTimeout(() => {
      setShowGuidMsg(true);
    }, 8000);

    const guidePopup = localStorage.getItem("guidePopup");

    if (guidePopup) {
      clearTimeout(id);
    }
  }, []);

  useEffect(() => {
    const hash = searchParams.get("verify_hash");

    if (!hash) return; // need show message error

    dispatch(verifyEmailAction(hash))
      .unwrap()
      .then((res) => {
        dispatch(setToken(res.token));

        localStorage.setItem("userToken", res.token);
        dispatch(setUsedData(res.user));
        dispatch(setRegModalStatus(true));
        dispatch(setBoardingStep(2));
      })
      .catch((error) => {
        console.log(error);
        setError("The verify code is expired or already was used.");
      });
  }, [searchParams]);

  const [homeSettings, setHomeSettings] = useState({
    nlError: "",
    open: false,
    openIsItRight: false,
  });

  const handleCloseGuide = () => {
    setShowGuidMsg(false);
    localStorage.setItem("guidePopup", "true");
  };

  return (
    <>
      <Helmet defaultTitle="Online Divorce Platform | SeparateSpace">
        <title>Online Divorce Platform | SeparateSpace</title>
        <meta
          name="description"
          content="SeparateSpace - Your trusted partner for navigating separation and divorce. Find resources, tools, and support to manage your separation journey effectively."
        />
      </Helmet>
      <GreatingSection />
      <section className={cn(s.feature, s.light_green_bg)}>
        <div className={cn(s.feature_container, s.feature_rtl)}>
          <div className={s.feature_content}>
            <Htag tag="h2">My Divorce Pathway</Htag>
            <p>
              A personalised, interactive pathway: step-by-step expert guidance
              tailored to your circumstances to take you through each of the
              complex legal, financial and practical phases of your divorce.
            </p>
            <p>
              <CheckIcon /> Know what to do, and when to do it.
            </p>
            <NavLink to="/features">
              <Button apearance="default">Learn more</Button>
            </NavLink>
          </div>

          <img src={pathWay} alt="" />
        </div>
      </section>
      <section className={cn(s.feature)}>
        <div className={cn(s.feature_container, s.feature_ltr)}>
          <div className={s.feature_content}>
            <Htag tag="h2">My Divorce Templates</Htag>
            <p>
              Emails, letters and documents drafted by our legal experts for you
              to use at all the key moments.
            </p>
            <p>
              <CheckIcon /> Have the words you need, when you need them.
            </p>
            <NavLink to="/features">
              <Button apearance="default">Learn more</Button>
            </NavLink>
          </div>

          <img src={feature_2} alt="" />
        </div>
      </section>
      <section className={cn(s.feature, s.blue_bg)}>
        <div className={cn(s.feature_container, s.feature_rtl)}>
          <div className={s.feature_content}>
            <Htag tag="h2">My Children</Htag>
            <p>
              Legal guidance as you put arrangements in place for your children,
              alongside advice and scripts prepared by our psychologist.
            </p>
            <p>
              <CheckIcon /> Build a positive co-parenting relationship with your
              ex and parent with confidence as your family reshapes.
            </p>
            <NavLink to="/features">
              <Button apearance="default">Learn more</Button>
            </NavLink>
          </div>

          <img src={phone2} alt="" />
        </div>
      </section>

      <section className={cn("section", s.team_section)}>
        <TeamIcon className={s.team_img} />
        <Htag tag="h2">Created by experts</Htag>
        <p className={s.subtitle}>
          We are a passionate and committed team working to revolutionise
          divorce support.
        </p>
        <div className={s.team}>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={AB} alt="" />
            </div>
            <Htag tag="h4">Amanda Bell</Htag>
            <p>Legal Expert</p>
          </div>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={VN} alt="" />
            </div>
            <Htag tag="h4">Victoria Nottage</Htag>
            <p>Legal Expert</p>
          </div>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={JH} alt="" />
            </div>
            <Htag tag="h4">Joanna Harrison</Htag>
            <p>Relationships Expert</p>
          </div>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={ES} alt="" />
            </div>
            <Htag tag="h4">Dr Ellen Shumka</Htag>
            <p>Psychologist</p>
          </div>
        </div>
        <Button apearance="secondary" onClick={() => navigate(`/team`)}>
          Meet our team
        </Button>
      </section>
      <section className={s.newsletter_section}>
        <GuideFormComponent />
      </section>
      <section className={cn("section", s.testimonials)}>
        <div className={s.testimonialHeader}>
          <CustomerStories />
          <Htag tag="h2">What our members think</Htag>
        </div>
        <div className={s.testimonialsList}>
          {testimonialData.map((testimonialData, index) => {
            if (isMobile) {
              if (index < 3)
                return (
                  <Card key={index} className={s.testimonialsList_item}>
                    {/* <Rating rate={testimonialData.rate} /> */}
                    <Htag tag="h4">{testimonialData.headline}</Htag>
                    <div className={s.testimonialsList_content}>
                      {testimonialData.value}
                    </div>
                    <h4>{testimonialData.author}</h4>
                  </Card>
                );
            } else {
              return (
                <Card  key={index} className={s.testimonialsList_item}>
                  {/* <Rating rate={testimonialData.rate} /> */}
                  <Htag tag="h4">{testimonialData.headline}</Htag>
                  <div className={s.testimonialsList_content}>
                    {testimonialData.value}
                  </div>
                  <h4>{testimonialData.author}</h4>
                </Card>
              );
            }
          })}
        </div>
      </section>
      <section className={cn("section", s.click_way)}>
        <Squar_round className={s.click_way_icon} />
        <Htag tag="h2" className={s.click_way_header}>
          A calm affordable divorce is just a click away
        </Htag>
        <ul>
          <li>
            <StepCircle
              className={cn(s.click_way_step)}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              1
            </StepCircle>
            <Htag tag="h4">Sign up</Htag>
            <p>
              Choose a subscription and tell us about you and your
              circumstances.
            </p>
          </li>
          <li>
            <StepCircle
              className={cn(s.click_way_step)}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              2
            </StepCircle>
            <Htag tag="h4">Get clarity</Htag>
            <p>We’ll create your personalised pathway and unique toolkit.</p>
          </li>
          <li>
            <StepCircle
              className={cn(s.click_way_step)}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              3
            </StepCircle>
            <Htag tag="h4">Take action</Htag>
            <p>
              Understand what you need to do, how to do it and move forward with
              confidence.
            </p>
          </li>
        </ul>
      </section>
      <ProffessionalFeedbackComponent />

      <section className={cn("section", s.faq)}>
        <Htag tag="h2">FAQ</Htag>
        <Accordion accordionData={faq}></Accordion>
      </section>
      <section className={cn(s.supportedBy, "section")}>
        <div className={s.supportHeader}>
          <SupportIcon />
          <Htag tag="h2">Supported by</Htag>
        </div>
        <div className={cn(s.support_list, s.separated)}>
          <img src={uKRI_IUK} alt="" />
          <img src={breakthorugh} alt="breakthorugh" />
        </div>
      </section>

      <section className={cn(s.contactSection, "darkBg")}>
        <div className={s.leftPart}>
          <Htag tag="h2">Need Help?</Htag>
          <Help />
        </div>
        <div className={s.rightPart}>
          <Htag tag="h3">
            Our Member Support Team is here to help via email or call.
          </Htag>
          <p>Available Monday to Friday, 10am - 4pm.</p>
          <Button
            apearance="primary-light"
            className={s.rightPart_btn}
            onClick={() => navigate(`/contact`)}
          >
            Contact Us
          </Button>
        </div>
      </section>
      <InstagramFeed />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={homeSettings.open}
        autoHideDuration={6000}
        onClose={() => setHomeSettings({ ...homeSettings, open: false })}
      >
        <Alert
          onClose={() => setHomeSettings({ ...homeSettings, open: false })}
          severity={!homeSettings.nlError ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!homeSettings.nlError
            ? "Thanks for subscribing.  We’ll be in touch soon."
            : homeSettings.nlError}
        </Alert>
      </Snackbar>
      <IsItRight
        open={homeSettings.openIsItRight}
        close={() => {
          setHomeSettings({
            ...homeSettings,
            openIsItRight: !homeSettings.openIsItRight,
          });
        }}
      />
      <ModalStyled
        open={!!error}
        close={() => setError("")}
        className={s.verified_error_modal}
      >
        <p>
          <ErrorField error={error} />
        </p>
        {userToken ? (
          <p>
            Go to{" "}
            <span
              className={"hover-underline-animation_back"}
              onClick={() => {
                dispatch(setRegModalStatus(true));
                dispatch(setBoardingStep(3));
              }}
            >
              pricing process
            </span>{" "}
            or{" "}
            <NavLink
              className={"hover-underline-animation_back"}
              to="/dashboard"
            >
              your dashboard
            </NavLink>
          </p>
        ) : (
          <p>
            Please, if you already have an account,{" "}
            <span
              onClick={() => dispatch(openLoginModal(true))}
              className={"hover-underline-animation_back"}
            >
              log in
            </span>{" "}
            or{" "}
            <span
              onClick={() => dispatch(setRegModalStatus(true))}
              className={"hover-underline-animation_back"}
            >
              become our member
            </span>{" "}
          </p>
        )}
      </ModalStyled>

      <ModalStyled open={!!showGuidMsg} close={() => handleCloseGuide()}>
        <GuideFormComponent />
      </ModalStyled>
    </>
  );
}

export default Home;
