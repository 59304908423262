import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";

import * as yup from "yup";
import cn from "classnames";
import styles from "../OnBoarding.module.css";
import { NavLink } from "react-router-dom";
import {
  addServerError,
  setBoardingStep,
  setInterview,
  setPrevBoardingStep,
} from "store/onBoarding/onboarding-reducer";
import axios from "axios";
import { SIGNUP } from "store/config";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, ErrorField, Htag, StepCircle } from "components";
import { setUsedData } from "store/userInfo/userInfo-reducer";

export const validationSignupSchema = yup.object({
  terms: yup.bool().oneOf([true], "Field is required"),
});

export const AgreeForm = (): JSX.Element => {
  const initInterview = useAppSelector(
    (state) => state.onRegistration.interview
  );
  const activeStep = useAppSelector((state) => state.onRegistration.activeStep);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initInterview,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(setBoardingStep(activeStep));
      axios
        .post(SIGNUP(), JSON.stringify(initInterview), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          responseType: "json",
        })
        .then((res: any) => {
          dispatch(addServerError({}));
          dispatch(setUsedData(res.data.user));
     
          window.datahappy
            .trackUserCreation({
              userId: res.data.user.id.toString(),
              eventProperties: {
                method: "email",
                status: "registered",
              },
              userTraits: {
                name: initInterview.name,
                email: initInterview.email,
              },
            })
            .then(() => {
          
              dispatch(setBoardingStep(activeStep));
            })
            .catch((err: Error) => console.log(err));
        })
        .catch((error) => {
          if (error.response.data.errors[0].email) {
            dispatch(setPrevBoardingStep(2));
          } else {
            dispatch(addServerError("Something goes wrong"));
          }
        });
    },
  });

  const updateInterviewChecked = (ev: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(ev);
    dispatch(
      setInterview({
        ...initInterview,
        [`${ev.target.name}`]: ev.target.checked,
      })
    );
  };

  return (
    <form
      id="form-1"
      onSubmit={formik.handleSubmit}
      className={styles.form_group}
    >
      <header className={styles.onboarding_header}>
        <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
          <span className={styles.step_color}>{activeStep + 1}</span>
        </StepCircle>
        <Htag tag="h4">Terms and Conditions</Htag>
      </header>

      <p>
        You can check our our privacy policy{" "}
        <NavLink
          to="/privacy-policy"
          target="_blank"
          className="hover-underline-animation_back"
        >
          here
        </NavLink>
        . Our terms and conditions are{" "}
        <NavLink
          to="/terms-of-service"
          target="_blank"
          className="hover-underline-animation_back"
        >
          here
        </NavLink>
        .
      </p>

      <div className={cn(styles.left, styles.checkboxGroup)}>
        <FormControlLabel
          control={
            <Checkbox
              name="terms"
              color="default"
              onChange={updateInterviewChecked}
              onBlur={formik.handleBlur}
            />
          }
          label="I agree to the terms and conditions"
        />
        {formik.touched.terms && formik.errors.terms && (
          <ErrorField error={formik.errors.terms} />
        )}
      </div>
      <div className={styles.buttonGroup}>
        <Button apearance="secondary" type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};
