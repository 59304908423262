import s from "./Ticket.module.css";
import cn from "classnames";
import {
  deleteTicket,
  setNewTicketStatus,
} from "../../../store/tickets/tickets-thunk";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { Checkbox, Menu } from "@mui/material";
import { addToEndBoard } from "../../../store/tickets/tickets-reducer";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Card, DeleteIcon, Htag } from "../..";

import { TicketItemProps } from "./Ticket.props";
import { TicketModalView } from "../../TicketModalView/TicketModalView.component";
import { TicketItemView } from "./TicketItemView.component";
import { getDots } from "../../../utils/utils";
import { useNavigate } from 'react-router-dom';

const TicketItem = ({ ticket, ...props }: TicketItemProps): JSX.Element => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const href = window.location.pathname;

  const link = ticket.special_ticket_id
    ? `special_ticket_id=${ticket.special_ticket_id}`
    : `id=${ticket.id}`;

	const navigation = useNavigate();

  const dispatch = useAppDispatch();
  const boards = useAppSelector((state) => state.tickets.boards);


  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const handleDeleteTicket = () => {
    dispatch(deleteTicket(ticket.id));

    setContextMenu(null);
  };

  const handleSetStatus = (id: number): void => {
    const el = {
      ticket_id: ticket.id,
      status: id,
      sort: boards[id].tickets.length,
    };

    dispatch(setNewTicketStatus(el))
      .unwrap()
      .then(() => {
        dispatch(addToEndBoard(el));
      });

    setContextMenu(null);
  };

  const handleClose = (): void => {
    setContextMenu(null);
  };

  return (
    <>
      <Draggable
        draggableId={ticket.id ? ticket.id.toString() : ""}
        index={props.index}
      >
        {(provided) => (
          <div
            className={s.ticketCardContainer}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            {...props}
          >
            <Card
              onContextMenu={handleContextMenu}
              className={cn(s.ticketCard)}
            >
              <div className={s.activeTitle}>
                <div className={s.checkbox}>
                  <Checkbox
                    color="default"
                    name="terms"
                    onChange={(e) => {
                      handleSetStatus(Number(!ticket.status));
                    }}
                    checked={Boolean(ticket.status)}
                  />
                </div>

                <Htag tag="h3" onClick={() => { navigation(`${href}/${link}`);}}>
                  {ticket.title && ticket.title.slice(0, 66)}
                </Htag>
              </div>

              <div className={s.shortDesc} onClick={() =>  navigation(`${href}/${link}`)}>
                <p>
                  {`${ticket.short_description?.slice(0, 66)}${getDots(
                    ticket.short_description,
                    66
                  )}`}
                </p>
              </div>
            </Card>
          </div>
        )}
      </Draggable>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        className={s.rightClickMenu}
        sx={{
          "& .MuiBackdrop-root": { background: "transparent!important" },
        }}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {ticket.status !== 3 && (
          <li onClick={() => handleSetStatus(1)}>
            <CheckBoxIcon className={s.completeIcon} />
            Done
          </li>
        )}

        <li onClick={handleDeleteTicket} className={s.deleteTicketItem}>
          <DeleteIcon />
          Delete
        </li>
      </Menu>
      {/* <TicketModalView open={openTicket} close={handleCloseTicket}>
        <TicketItemView
          complete={(value: number) => handleSetStatus(value)}
          ticket={ticket}
          deleteItem={handleDeleteTicket}
        />
      </TicketModalView> */}
    </>
  );
};

export default TicketItem;
